"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCoreReturnPageData = exports.getCoreAnalysisClaimsData = exports.removeFavoriteShippingAccount = exports.addFavoriteShippingAccount = exports.getShippingAccounts = exports.getCoreReportsPageData = exports.resetPassword = exports.sendResetPassword = exports.logoutUser = exports.loginUser = exports.verifyLogin = exports.getUserData = void 0;
const Constants_1 = require("@/Constants");
const request_1 = require("./request");
const getUserData = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        return yield (0, request_1.request)(Constants_1.UserEndpoints.GET);
    }
    catch (error) {
        throw new Error(`Could not get user data, received the following error: ${error}`);
    }
});
exports.getUserData = getUserData;
const verifyLogin = (userName, password) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Constants_1.UserEndpoints.VERIFY_LOGIN, request_1.RequestType.POST, { userName, password });
        return result.data;
    }
    catch (error) {
        throw new Error(`Could not login user, received the following error: ${error}`);
    }
});
exports.verifyLogin = verifyLogin;
const loginUser = (username, password, shipToCustomerId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const result = yield (0, request_1.request)(Constants_1.UserEndpoints.LOGIN, request_1.RequestType.POST, {
            username,
            password,
            shipToCustomerId
        });
        return result.data;
    }
    catch (error) {
        throw new Error(`Could not login user, received the following error: ${error}`);
    }
});
exports.loginUser = loginUser;
const logoutUser = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const result = yield (0, request_1.request)(Constants_1.UserEndpoints.LOGOUT, request_1.RequestType.POST);
        return result.status;
    }
    catch (error) {
        const err = error;
        return (_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.status;
    }
});
exports.logoutUser = logoutUser;
const sendResetPassword = (userName) => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    try {
        const result = yield (0, request_1.request)(Constants_1.UserEndpoints.SEND_RESET_PASSWORD, request_1.RequestType.POST, { userName });
        return result === null || result === void 0 ? void 0 : result.status;
    }
    catch (error) {
        const err = error;
        return (_b = err === null || err === void 0 ? void 0 : err.response) === null || _b === void 0 ? void 0 : _b.status;
    }
});
exports.sendResetPassword = sendResetPassword;
const resetPassword = (userName, password, passwordConfirmation, token) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    try {
        const result = yield (0, request_1.request)(Constants_1.UserEndpoints.RESET_PASSWORD, request_1.RequestType.POST, {
            userName,
            password,
            passwordConfirmation,
            token
        });
        return result.status;
    }
    catch (error) {
        const err = error;
        return (_c = err === null || err === void 0 ? void 0 : err.response) === null || _c === void 0 ? void 0 : _c.data;
    }
});
exports.resetPassword = resetPassword;
const getCoreReportsPageData = (endpoint, params = '', responseType) => __awaiter(void 0, void 0, void 0, function* () {
    var _d;
    try {
        return yield (0, request_1.request)(endpoint + params, undefined, undefined, undefined, undefined, responseType);
    }
    catch (error) {
        const err = error;
        return (_d = err === null || err === void 0 ? void 0 : err.response) === null || _d === void 0 ? void 0 : _d.data;
    }
});
exports.getCoreReportsPageData = getCoreReportsPageData;
const getShippingAccounts = () => __awaiter(void 0, void 0, void 0, function* () {
    var _e;
    try {
        return yield (0, request_1.request)(Constants_1.UserEndpoints.SHIPPING_ACCOUNTS);
    }
    catch (error) {
        const err = error;
        return (_e = err === null || err === void 0 ? void 0 : err.response) === null || _e === void 0 ? void 0 : _e.data;
    }
});
exports.getShippingAccounts = getShippingAccounts;
const addFavoriteShippingAccount = (payload) => __awaiter(void 0, void 0, void 0, function* () {
    var _f;
    try {
        return yield (0, request_1.request)(Constants_1.UserEndpoints.ADD_FAVORITE_SHIPPING_ACCOUNT, request_1.RequestType.POST, JSON.stringify(payload), undefined, undefined, undefined, undefined, { 'Content-Type': 'application/json' });
    }
    catch (error) {
        const err = error;
        return (_f = err === null || err === void 0 ? void 0 : err.response) === null || _f === void 0 ? void 0 : _f.data;
    }
});
exports.addFavoriteShippingAccount = addFavoriteShippingAccount;
const removeFavoriteShippingAccount = (payload) => __awaiter(void 0, void 0, void 0, function* () {
    var _g;
    try {
        return yield (0, request_1.request)(Constants_1.UserEndpoints.REMOVE_FAVORITE_SHIPPING_ACCOUNT, request_1.RequestType.POST, JSON.stringify(payload), undefined, true, undefined, undefined, { 'Content-Type': 'application/json' });
    }
    catch (error) {
        const err = error;
        return (_g = err === null || err === void 0 ? void 0 : err.response) === null || _g === void 0 ? void 0 : _g.data;
    }
});
exports.removeFavoriteShippingAccount = removeFavoriteShippingAccount;
const getCoreAnalysisClaimsData = (params, responseType) => __awaiter(void 0, void 0, void 0, function* () {
    var _h;
    try {
        return yield (0, request_1.request)(Constants_1.UserEndpoints.CORE_ANALYSIS_CLAIMS_DATA + params, undefined, undefined, undefined, undefined, responseType);
    }
    catch (error) {
        const err = error;
        return (_h = err === null || err === void 0 ? void 0 : err.response) === null || _h === void 0 ? void 0 : _h.data;
    }
});
exports.getCoreAnalysisClaimsData = getCoreAnalysisClaimsData;
const getCoreReturnPageData = (params, responseType) => __awaiter(void 0, void 0, void 0, function* () {
    var _j;
    try {
        return yield (0, request_1.request)(Constants_1.UserEndpoints.CORE_ANALYSIS_CLAIMS_DATA + params, undefined, undefined, undefined, undefined, responseType);
    }
    catch (error) {
        const err = error;
        return (_j = err === null || err === void 0 ? void 0 : err.response) === null || _j === void 0 ? void 0 : _j.data;
    }
});
exports.getCoreReturnPageData = getCoreReturnPageData;
