"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserSlice = exports.fetchUserData = exports.addressType = exports.continentNames = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const user_1 = require("../../Endpoints/user");
var continentNames;
(function (continentNames) {
    continentNames[continentNames["Undefined"] = 0] = "Undefined";
    continentNames[continentNames["Global"] = 1] = "Global";
    continentNames[continentNames["Africa"] = 2] = "Africa";
    continentNames[continentNames["Antarctica"] = 3] = "Antarctica";
    continentNames[continentNames["Asia"] = 4] = "Asia";
    continentNames[continentNames["Europe"] = 5] = "Europe";
    continentNames[continentNames["AustraliaOceania"] = 6] = "AustraliaOceania";
    continentNames[continentNames["NorthAmerica"] = 7] = "NorthAmerica";
    continentNames[continentNames["SouthAmerica"] = 8] = "SouthAmerica";
})(continentNames = exports.continentNames || (exports.continentNames = {}));
var addressType;
(function (addressType) {
    addressType[addressType["Undefined"] = 0] = "Undefined";
    addressType[addressType["Public"] = 1] = "Public";
    addressType[addressType["Shipping"] = 2] = "Shipping";
    addressType[addressType["Billing"] = 4] = "Billing";
})(addressType = exports.addressType || (exports.addressType = {}));
const initialState = {
    isAuthenticated: false,
    isLoading: false
};
exports.fetchUserData = (0, toolkit_1.createAsyncThunk)('user', () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield (0, user_1.getUserData)().then(data => data.data);
    return response;
}));
exports.UserSlice = (0, toolkit_1.createSlice)({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(exports.fetchUserData.pending, state => {
            state.isLoading = true;
        });
        builder.addCase(exports.fetchUserData.fulfilled, (state, action) => {
            var _a, _b, _c, _d, _e;
            state.isAuthenticated = action.payload.isAuthenticated;
            state.email = action.payload.email;
            state.isActive = action.payload.isActive;
            state.isLockedOut = action.payload.isLockedOut;
            state.userName = action.payload.userName;
            state.isLoading = false;
            state.continent = action.payload.continent;
            state.userProfile = action.payload.userProfile;
            state.roles = action.payload.roles;
            state.firstName = action.payload.firstName;
            state.lastName = action.payload.lastName;
            state.axaptaProfile = {
                canSeePricing: (_a = action.payload.axaptaProfile) === null || _a === void 0 ? void 0 : _a.canSeePricing,
                shippingCustomerId: (_b = action.payload.axaptaProfile) === null || _b === void 0 ? void 0 : _b.shippingCustomerId,
                preferredShippingAddress: (_c = action.payload.axaptaProfile) === null || _c === void 0 ? void 0 : _c.preferredShippingAddress,
                preferredBillingAddress: (_d = action.payload.axaptaProfile) === null || _d === void 0 ? void 0 : _d.preferredBillingAddress,
                contactPersonName: (_e = action.payload.axaptaProfile) === null || _e === void 0 ? void 0 : _e.contactPersonName
            };
            state.hasAxaptaData = action.payload.hasAxaptaData;
        });
        builder.addCase(exports.fetchUserData.rejected, state => {
            state.isLoading = false;
        });
    }
});
exports.default = exports.UserSlice.reducer;
