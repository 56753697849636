"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompareEndpoints = exports.MyProfileEndpoints = exports.DistributorsEndpoints = exports.OrdersHistoryEndpoints = exports.CheckoutEndpoints = exports.CartEndpoints = exports.AppEndpoints = exports.UserEndpoints = exports.BaseEndpoints = exports.Cookies = void 0;
exports.Cookies = {
    TRANSLATIONS_REFETCH_TIME: 'TRANSLATIONS_REFETCH_TIME',
    TRANSLATIONS_REFETCH_TIME_IN_MINUTES: 30,
    CATALOG_TRANSLATIONS_REFETCH_TIME: 'CATALOG_TRANSLATION_REFETCH_TIME',
    CATALOG_LANGUAGE_COOKIE_NAME: 'CATALOG_LANGUAGE_COOKIE'
};
exports.BaseEndpoints = {
    BASE_API_PATH: '/api/haldex/v1.0'
};
exports.UserEndpoints = {
    GET: exports.BaseEndpoints.BASE_API_PATH + '/user/get',
    SEND_RESET_PASSWORD: exports.BaseEndpoints.BASE_API_PATH + '/user/SendPasswordReset',
    RESET_PASSWORD: exports.BaseEndpoints.BASE_API_PATH + '/user/ResetPassword',
    LOGIN: exports.BaseEndpoints.BASE_API_PATH + '/user/login',
    VERIFY_LOGIN: exports.BaseEndpoints.BASE_API_PATH + '/user/VerifyLogin',
    LOGOUT: exports.BaseEndpoints.BASE_API_PATH + '/user/logout',
    SHIPPING_ACCOUNTS: exports.BaseEndpoints.BASE_API_PATH + '/user/GetShippingAccounts',
    ADD_FAVORITE_SHIPPING_ACCOUNT: exports.BaseEndpoints.BASE_API_PATH + '/user/AddFavoriteShippingAccount',
    REMOVE_FAVORITE_SHIPPING_ACCOUNT: exports.BaseEndpoints.BASE_API_PATH + '/user/RemoveFavoriteShippingAccount',
    CORE_REPORTS_TABLE_DATA: exports.BaseEndpoints.BASE_API_PATH + '/axapta-core/reports',
    GET_USER_PROFILE: exports.BaseEndpoints.BASE_API_PATH + '/user/GetUserProfile',
    CORE_ANALYSIS_CLAIMS_DATA: exports.BaseEndpoints.BASE_API_PATH + '/axapta-core/analysis'
};
exports.AppEndpoints = {
    GET: exports.BaseEndpoints.BASE_API_PATH + '/app/getsettings',
    TRANSLATIONS: exports.BaseEndpoints.BASE_API_PATH + '/app/translations',
    HEADER: exports.BaseEndpoints.BASE_API_PATH + '/app/getheader',
    FOOTER: exports.BaseEndpoints.BASE_API_PATH + '/app/getfooter',
    SERVICE_CENTER_LINKS: exports.BaseEndpoints.BASE_API_PATH + '/app/GetServiceLinks',
    ERROR_PAGES: exports.BaseEndpoints.BASE_API_PATH + '/app/GetErrorPages',
    API_KEYS: exports.BaseEndpoints.BASE_API_PATH + '/app/GetApiKeys',
    EPI_FORM: exports.BaseEndpoints.BASE_API_PATH + '/epiforms'
};
exports.CartEndpoints = {
    GET: exports.BaseEndpoints.BASE_API_PATH + '/cart/get',
    PUT: exports.BaseEndpoints.BASE_API_PATH + '/cart/put',
    PUTLIST: exports.BaseEndpoints.BASE_API_PATH + '/cart/putlist',
    REMOVE_ITEM: exports.BaseEndpoints.BASE_API_PATH + '/cart/removeItem',
    EMPTY: exports.BaseEndpoints.BASE_API_PATH + '/cart/delete',
    ALL_SHIPPING_DATES: exports.BaseEndpoints.BASE_API_PATH + '/cart/cart-shipping-date',
    ITEM_SHIPPING_DATE: exports.BaseEndpoints.BASE_API_PATH + '/cart/item-shipping-date',
    SET_SHIPPING_OPTION: exports.BaseEndpoints.BASE_API_PATH + '/cart/shipping-option',
    GET_EXPRESS_ORDERS_DAYS_OFF: exports.BaseEndpoints.BASE_API_PATH + '/app/GetExpressOrderSettings',
    GET_CUSTOMER_SERVICE_NOTES: exports.BaseEndpoints.BASE_API_PATH + '/user-order-info/user-order-notes',
    GET_PRICES_INFO: exports.BaseEndpoints.BASE_API_PATH + '/cart/prices-info',
    PUTLIST_QUICKORDER: exports.BaseEndpoints.BASE_API_PATH + '/quickorder/putlist'
};
exports.CheckoutEndpoints = {
    GET: exports.BaseEndpoints.BASE_API_PATH + '/checkout',
    SUBMIT: exports.BaseEndpoints.BASE_API_PATH + '/checkout/submit'
};
exports.OrdersHistoryEndpoints = {
    GET: exports.BaseEndpoints.BASE_API_PATH + '/order-history/orders',
    GET_CACHED: exports.BaseEndpoints.BASE_API_PATH + '/order-history/cachedOrders',
    GET_INVOICES: exports.BaseEndpoints.BASE_API_PATH + '/order-history/invoices',
    GET_CACHED_INVOICES: exports.BaseEndpoints.BASE_API_PATH + '/order-history/cachedInvoices',
    GET_ORDER_DETAILS: exports.BaseEndpoints.BASE_API_PATH + '/order-history/order-details',
    GET_INVOICE_DETAILS: exports.BaseEndpoints.BASE_API_PATH + '/order-history/invoice-details',
    GET_CLAIM_STATUS: exports.BaseEndpoints.BASE_API_PATH + '/claim/status',
    COPY_TO_CART: exports.BaseEndpoints.BASE_API_PATH + '/order-history/orders/'
};
exports.DistributorsEndpoints = {
    GET: exports.BaseEndpoints.BASE_API_PATH + '/distributor-search',
    GET_FILTERS: exports.BaseEndpoints.BASE_API_PATH + '/distributor-search/filters'
};
exports.MyProfileEndpoints = {
    PASSWORD_CHANGE: exports.BaseEndpoints.BASE_API_PATH + '/my-profile/change-password',
    PROFILE_CHANGE: exports.BaseEndpoints.BASE_API_PATH + '/my-profile/change-profile'
};
exports.CompareEndpoints = {
    COMPARE: '/CompareVariants'
};
exports.default = exports.Cookies;
